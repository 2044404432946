<template>
  <div>
    <div class="column" v-for="(item, index) in orderItems" :key="index">
      <div class="column px-2" v-if="item.order_item_status_id !== ORDER_STATUS.DELETED && item.active">
        <ion-row>
          <ion-col class="pt-2" size="auto">
            <div class="product-image">
              <div class="image-wrapper">
                <td-image
                  :image="item?.image"
                  :imagePath="IMAGE_PATH.PRODUCT"
                  :imageDefault="DEFAULT_PRODUCT_IMAGE"
                  :key="item?.image"
                ></td-image>
              </div>
              <div v-if="item?.halal" class="halal-label">
                <p class="halal">{{ $t('halal') }}</p>
              </div>
            </div>
          </ion-col>
          <ion-col class="ml-2 pt-2">
            <ion-label class="mb-2 name">{{ item?.product_name }}</ion-label>
            <div v-if="item?.is_catch_weight" class="mt-1 row-item">
              <ion-text class="text-grey fs-13">{{ $t('catch_weight_cap') }}</ion-text>
              <tooltip :isViewOrder="true" />
            </div>
            <div class="row">
              <div class="column">
                <ion-text class="unit-price">{{ unitPrice(item?.sale_unit_price) }}</ion-text>
                <div>
                  <!-- Hide discound Label for now -->
                  <!-- <div class="discount-label mt-2">
            <p class="discount">{{ 50% + ' OFF' }}</p>
          </div> -->
                  <!-- price before discount-->
                  <!-- <div class="discount-label mt-2">
            <p class="discount-price">S$10.00</p>
          </div> -->
                </div>
              </div>
              <div class="column text-end">
                <ion-text class="text-primary">{{ quantity(item) }}</ion-text>
                <ion-text class="total-price">{{ totalPrice(item?.total_price) }}</ion-text>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </div>
      <div
        class="line-grey my-2"
        v-if="item.order_item_status_id !== ORDER_STATUS.DELETED && item.active"
      ></div>
    </div>
  </div>
</template>
<script>
import Tooltip from '@/components/molecules/tooltip/Tooltip.vue';
import { DEFAULT_PRODUCT_IMAGE, IMAGE_PATH } from '@/modules/sale/constants/index';
import { displayPrice } from '@/modules/sale/services/libs/helper';
import { ORDER_STATUS } from '@/services/shared/helper/constants';
import { priceFormatter } from '@/utils/';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'ViewOrderItem',
  components: {
    Tooltip
  },
  props: {
    orderItems: {
      type: Array,
      default: function () {
        return [];
      }
    },
    currencySymbol: {
      type: String,
      default: ''
    }
  },

  setup(props) {
    const unitPrice = (price) => {
      return priceFormatter(props.currencySymbol, displayPrice(price));
    };

    const quantity = (item) => {
      const countQty = item.total_qty;
      const weightQty = displayPrice(countQty);
      return item.is_order_by_weight ? weightQty.toString() + ' Kg' : countQty.toString() + 'x';
    };

    const totalPrice = (price) => {
      return priceFormatter(props.currencySymbol, displayPrice(price));
    };

    return {
      priceFormatter,
      DEFAULT_PRODUCT_IMAGE,
      IMAGE_PATH,
      ORDER_STATUS,
      unitPrice,
      quantity,
      totalPrice
    };
  }
});
</script>

<style lang="scss" scoped>
.column {
  display: flex;
  flex-direction: column;
}
.row-item {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-content: center;
  align-items: center;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: space-between;
  justify-content: space-between;
  justify-items: start;
}
.text-end {
  text-align: right;
}
.text-start {
  text-align: right;
}
.image-wrapper {
  height: 65px;
  width: 65px;
  border-radius: 0px 0px 0px 0px;
  overflow: hidden;
}
.line-grey {
  height: 1.2px;
  width: 100%;
  background-color: #e0e0e0;
}
.product-image::part(image) {
  position: relative;
  height: 65px;
  width: 65px;
  overflow: hidden;
  display: block;
  border-radius: 0px 0px 0px 0px;
}
ion-img {
  display: block;
  border-radius: 0px 0px 0px 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.halal-label {
  position: absolute;
  margin-top: -12px;
  left: 8;
  width: 65px;
  height: 15px;
  background: #12b76a;
  border-radius: 0px 0px 0px 0px;
  color: #fff;
  font-size: 10px;
  text-align: center;
  box-sizing: border-box;
}
.halal {
  margin-top: 0.2px;
}
.discount {
  font-weight: 700;
  font-size: 10px;
  margin-top: -0.1px;
}
.discount-label {
  position: absolute;
  height: 18px;
  min-width: 58px;
  max-width: 60px;
  left: 0px;
  right: 0px;
  top: 12px;
  gap: 10px;
  padding-top: 2px;
  margin-left: -8px;
  text-align: center;
  color: #ffffff;
  border-radius: 6px 6px 6px 0px;
  background: #d92d20;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
}
.name {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #212121;
}
.stock {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.5px;
  color: #757575;
}
.price-range {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.38px;
  color: #212121;
}
.unit-price {
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #616161;
}
.total-price {
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.7px;
  color: #000000;
}
.text-grey {
  color: #9e9e9e;
}
</style>
